
<div class="auth">
    Convolo Dialer user ID:<input class="input" [(ngModel)]="settings.userId" placeholder="userId"/><br>
</div>
<div class="auth">
    Upsales API token: <input class="input" [(ngModel)]="settings.token" placeholder="upsales token"/><br>
<button class="btn" *ngIf="settings.userId && settings.token && (!companyName)" (click) = login()>Next</button>
</div>

<div *ngIf="settingsSaved" class = "success">Success!</div>

<div *ngIf="companyName">
<div class="company line"> Upsales company: {{companyName}} </div>

<div *ngIf="users">
    <table  class="table" id="usertable">
        <thead>
            <th>Webphone short number</th>
            <th>Upsales user</th>
        </thead>
        <tbody>
            <tr *ngFor="let user of users; let i = index">
                <td><input [(ngModel)]="user.shortnum" class="input"/></td>
                <td>
                    <select [(ngModel)]="user.upsalesUserId" class="input">
                        <option *ngFor="let upsalesUser of upsalesUsers" [value]="upsalesUsers.indexOf(upsalesUser)">                    
                        {{upsalesUser.name}}
                        </option>
                    </select>
                </td>
                <td>
                    <button class="btn btn-delete" type="button" (click)="deleteUserTableRow(i)">✕</button>
                </td>
            </tr>
        </tbody>
        <button class="btn btn-add" type="button" (click)="addUserTableRow()">Add</button>
    </table>
</div>

<div class="activity line">
Type of activity to create when the call happens: <select class="input" [(ngModel)]="settings.defaultActivityType">
    <option *ngFor="let activityType of activityTypes" [value]="activityType.id" >                    
    {{activityType.name}}
    </option>
</select>
<br>
</div>

<div class="user line">
Default user:
<select [(ngModel)]="defaultUserId" class="input">
    <option *ngFor="let upsalesUser of upsalesUsers" [value]="upsalesUsers.indexOf(upsalesUser)" >                    
    {{upsalesUser.name}}
    </option>
</select>
<br>
</div>

<label class="line">
    Create contacts for unknown numbers:
<input [(ngModel)]="settings.createContactIfUndefined" type="checkbox"/></label>
<br>
<div *ngIf="settings.createContactIfUndefined">
    <div class="client line">
Client to assign new contacts: 
    <input class="input" [(ngModel)]="defaultClientName" list="client" (ngModelChange)= findCompanies()>
    <div *ngIf="defaultClientNameValid"  class="searchValid">✓</div>
    <div *ngIf="!defaultClientNameValid" class="searchError" >{{clientSearchError}}<br></div>
        <datalist *ngIf="clients" [(ngModel)]="settings.defaultClient" id="client">
            <option *ngFor="let client of clients" [value]="client.name" >
        </datalist>

    </div>   

<br>

<div class="contact line">
Contact name for created contacts: 
<input [(ngModel)]="settings.defaultContactName" class="input"/>
</div>
</div>
<button (click) = sendSettings() class="btn btn-save">Save</button>
</div>
