import { Component, OnInit, NgZone } from '@angular/core';
import { settings, UpsalesActivityTypeDto, UpsalesUserDto, UpsalesClientDto } from './types';
import { upsalesLeadService } from './upsales-leads.service';
import { ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-upsales-leads',
  templateUrl: './upsales-leads.component.html',
  styleUrls: ['./upsales-leads.component.css']
})
export class UpsalesLeadsComponent implements OnInit {

  constructor(private leadService: upsalesLeadService,
        private route: ActivatedRoute) { 
    this.route.queryParams.subscribe(params => {
      this.settings.widget_key = params["widget_key"]
      this.settings.token = params["token"]
      if(this.settings.token && this.settings.widget_key)
        this.login()

  });
  }
  

  settings: settings  = {
    widget_key: undefined,
    token: undefined,
    defaultContactName: "Anonymous Caller",
    defaultClient: undefined,
    companyId: undefined,
    defaultUser: undefined,
    defaultActivityType: undefined,
    defaultActivityName: undefined,
    createContactIfUndefined: true,
    users: {},
    contact_name_creating_script: undefined,
    comment_creating_script: undefined,
    pre_call_script: undefined,

  };
  users: {operator_name: string, upsalesUserId: number | undefined}[] = new Array(); 
  activityTypes: UpsalesActivityTypeDto[] = [{id: '', name: "Do not create activity", roles: undefined}];
  upsalesUsers: UpsalesUserDto[];
  clients: UpsalesClientDto[];
  defaultClient: UpsalesClientDto;
  defaultUserId: number | undefined
  companyName: string;
  defaultUserEmail: string;
  defaultClientName: string;
  defaultClientNameValid: boolean = false;
  clientSearchError: string;
  settingsSaved: boolean = false;

  customScripts: boolean = false;

  contact_name_creating_script_rows: number;
  comment_creating_script_rows: number;
  pre_call_script_rows: number;

  ngOnInit(): void {
  }

  async login(){
    
    this.leadService.getUserSelf(this.settings.token).subscribe(async data => {
      this.settings.companyId = data.data.client.id
      this.companyName = data.data.client.name
      this.defaultUserEmail = data.data.email

      this.activityTypes = this.activityTypes.concat(
        await this.leadService.getActivityTypes(this.settings.token))
      this.settings.defaultActivityType = this.activityTypes.find(i => i.name.startsWith("Tele"))?.id
      this.settings.defaultActivityName = "New call with convolo"  
      this.upsalesUsers = await this.leadService.getUsers(this.settings.token)
      //this.users = [{operator_name: "", upsalesUserId: undefined}]
      if (this.defaultUserEmail)
        this.defaultUserId = this.upsalesUsers.findIndex(i => i.email === this.defaultUserEmail)
     
      this.defaultClientName = "Upsales Nordic AB"
      this.findCompanies()
      this.leadService.getSettings(this.settings.widget_key, this.settings.token).subscribe(
        data => this.uploadSettings(data)
      )
    })

    
    
  }

  async findCompanies(){
    this.clients = await this.leadService.getClients(this.settings.token, this.defaultClientName)
    if (!this.clients)
      this.clientSearchError = "Too many clients found. Please type a few more characters"
    else {
      this.defaultClientNameValid = this.clients.find(i => i.name === this.defaultClientName)?true:false
    //console.log(this.clients)
    
    if (this.clients.length === 0)
      this.clientSearchError = "No clients found."
    else
      this.clientSearchError = ""
    }
    

  }

  sendSettings(){
    this.users.forEach(user => {
      if (user.operator_name && (typeof user.upsalesUserId !== undefined))
        this.settings.users[user.operator_name] = this.upsalesUsers[user.upsalesUserId]
    })
    if (typeof this.defaultUserId !== undefined)
      this.settings.defaultUser = this.upsalesUsers[this.defaultUserId]
    
    const defaultClient = this.clients.find(i=> i.name === this.defaultClientName)
    if (defaultClient)
      this.settings.defaultClient = defaultClient.id
    
    this.leadService.sendSettings(this.settings).subscribe(
      data => {

        this.settingsSaved = true
        this.uploadSettings(data)
      }
    )
  }

  async uploadSettings(data: settings){
    this.settings = {...this.settings, ...data}
    if (data.users){
      this.settings.users = {}
      this.users = new Array()
      Object.keys(data.users).forEach(user =>{
        this.users.push({
          operator_name: user, 
          upsalesUserId: this.upsalesUsers.findIndex(i => i.id === data.users[user].id)
            
        })
      })
    }
    this.defaultUserId = this.upsalesUsers.findIndex(i => i.id === data.defaultUser.id) 
    this.defaultClient = await this.leadService.getClinet(this.settings.token, data.defaultClient)
    this.defaultClientName = this.defaultClient.name
    if (data.contact_name_creating_script || data.pre_call_script || data.comment_creating_script)
      
      this.customScripts = true
    
    this.pre_call_script_rows = data.pre_call_script?data.pre_call_script.match(/\n/g).length:2
    this.comment_creating_script_rows = data.comment_creating_script?data.comment_creating_script.match(/\n/g).length:2
    this.contact_name_creating_script_rows = data.contact_name_creating_script?data.contact_name_creating_script.match(/\n/g).length:2


    this.findCompanies()   
  }

  test(){
    console.log("test")
    console.log(this.users)
    console.log(this.defaultUserId)
    console.log(this.upsalesUsers)
  
  }

  toggleCustomScripts(){
    this.customScripts = !this.customScripts
  }

  addUserTableRow(){
    this.users.push({operator_name: "", upsalesUserId: undefined})
  }

  deleteUserTableRow(index) {
    this.users.splice(index, 1);
  }

  
  commentRows(){
    this.comment_creating_script_rows = this.settings.comment_creating_script.match(/\n/g).length +1
  }

  contactRows(){
    this.contact_name_creating_script_rows = this.settings.contact_name_creating_script.match(/\n/g).length +1
  }

  precallRows(){
    this.pre_call_script_rows = this.settings.pre_call_script.match(/\n/g).length +1
  }

}
