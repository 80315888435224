
<div class="auth">
    Widget key:<input class="input" [(ngModel)]="settings.widget_key" placeholder="widget key"/>
</div>
<div class="auth">
    Upsales API token: <input class="input" [(ngModel)]="settings.token" placeholder="upsales token"/><br>
<button class="btn" *ngIf="settings.widget_key && settings.token && (!companyName)" (click) = login()>Next</button>
</div>

<div *ngIf="settingsSaved" class = "success">Success!</div>

<div *ngIf="companyName">
<div class="company line"> Upsales company: {{companyName}} </div>

<div *ngIf="users">
    <table  class="table" id="usertable">
        <thead>
            <th>Operator name</th>
            <th>Upsales user</th>
        </thead>
        <tbody>
            <tr *ngFor="let user of users; let i = index">
                <td><input class="input" [(ngModel)]="user.operator_name"/></td>
                <td>
                    <select class="input" [(ngModel)]="user.upsalesUserId">
                        <option *ngFor="let upsalesUser of upsalesUsers" [value]="upsalesUsers.indexOf(upsalesUser)">                    
                        {{upsalesUser.name}}
                        </option>
                    </select>
                </td>
                <td>
                    <button class="btn btn-delete" type="button" (click)="deleteUserTableRow(i)">✕</button>
                </td>
            </tr>
        </tbody>
        <button class="btn btn-add" type="button" (click)="addUserTableRow()">Add</button>
    </table>
</div>

<div class="activity line">
Type of activity to create when the call happens: <select class="input" [(ngModel)]="settings.defaultActivityType">
    <option *ngFor="let activityType of activityTypes" [value]="activityType.id" >                    
    {{activityType.name}}
    </option>
</select>
<br>
</div>

<div class="activity line" *ngIf="settings.defaultActivityType">
    Name of created activity: <input class="input" [(ngModel)]="settings.defaultActivityName"/>
</div>

<div class="user line">
Default user:
<select [(ngModel)]="defaultUserId" class="input">
    <option *ngFor="let upsalesUser of upsalesUsers" [value]="upsalesUsers.indexOf(upsalesUser)" >                    
    {{upsalesUser.name}}
    </option>
</select>
<br>
</div>

<label class="line">
    Create contacts for unknown numbers:
<input [(ngModel)]="settings.createContactIfUndefined" type="checkbox"/></label>
<br>
<div *ngIf="settings.createContactIfUndefined">
    <div class="client line">
Client to assign new contacts: 
    <input  class="input" [(ngModel)]="defaultClientName" list="client" (ngModelChange)= findCompanies()>
    <div *ngIf="defaultClientNameValid"  class="searchValid">✓</div>
    <div *ngIf="!defaultClientNameValid" class="searchError" >{{clientSearchError}}<br></div>
        <datalist *ngIf="clients" [(ngModel)]="settings.defaultClient" id="client">
            <option *ngFor="let client of clients" [value]="client.name" >
        </datalist>

    </div>   

<br>


<div class="contact line">
Contact name for created contacts: 
<input class="input" [(ngModel)]="settings.defaultContactName"/>
</div>
</div>

<button (click)="toggleCustomScripts()" class="btn">Show/hide custom scripts</button>
<br>
<div *ngIf="customScripts" class="line">

Name creating script:<br>
<textarea  [(ngModel)]="settings.contact_name_creating_script" class="script" rows="{{contact_name_creating_script_rows}}" (ngModelChange)="contactRows()"></textarea>
<br>

Comment creating script:<br>
<textarea [(ngModel)]="settings.comment_creating_script" class="script" rows="{{comment_creating_script_rows}}" (ngModelChange)="commentRows()"></textarea>
<br>

Pre_call script:<br>
<textarea [(ngModel)]="settings.pre_call_script" class="script" rows="{{pre_call_script_rows}}" (ngModelChange)=precallRows()></textarea>
<br>


</div>


<button (click) = sendSettings() class="btn btn-save">Save</button>
</div>
