import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { settings, UpsalesActivityTypeDto, UpsalesClientDto, UpsalesUserDto } from './types';
import { upsalesDialerService } from './upsales-dialer.service';

@Component({
  selector: 'app-upsales-dialer',
  templateUrl: './upsales-dialer.component.html',
  styleUrls: ['./upsales-dialer.component.css']
})
export class UpsalesDialerComponent implements OnInit {

  constructor(private dialerService: upsalesDialerService, private route: ActivatedRoute) { 
    this.route.queryParams.subscribe(params => {
      this.settings.userId = params["userId"]
      this.settings.token = params["token"]
      if(this.settings.token && this.settings.userId)
        this.login()

  });
  }
  settings: settings  = {
    userId: undefined,
    token: undefined,
    defaultContactName: "Anonymous Caller",
    defaultClient: undefined,
    companyId: undefined,
    defaultUser: undefined,
    defaultActivityType: undefined,
    createContactIfUndefined: true,
    users: {},
  };
  users: {shortnum: string, upsalesUserId: number | undefined}[] = new Array(); 
  activityTypes: UpsalesActivityTypeDto[] = [{id: '', name: "Do not create activity", roles: undefined}];
  upsalesUsers: UpsalesUserDto[];
  clients: UpsalesClientDto[];
  defaultClient: UpsalesClientDto;
  defaultUserId: number | undefined
  companyName: string;
  defaultUserEmail: string;
  defaultClientName: string;
  defaultClientNameValid: boolean = false;
  clientSearchError: string;
  settingsSaved: boolean = false;

  ngOnInit(): void {
  }

  async login(){
    
    this.dialerService.getUserSelf(this.settings.token).subscribe(async data => {
      this.settings.companyId = data.data.client.id
      this.companyName = data.data.client.name
      this.defaultUserEmail = data.data.email

      this.activityTypes = this.activityTypes.concat(
        await this.dialerService.getActivityTypes(this.settings.token))
      this.settings.defaultActivityType = this.activityTypes.find(i => i.name.startsWith("Tele"))?.id
        
      this.upsalesUsers = await this.dialerService.getUsers(this.settings.token)
      //this.users = [{shortnum: "", upsalesUserId: undefined}]
      if (this.defaultUserEmail)
        this.defaultUserId = this.upsalesUsers.findIndex(i => i.email === this.defaultUserEmail)
     
      this.defaultClientName = "Upsales Nordic AB"
      this.findCompanies()
      this.dialerService.getSettings(this.settings.userId, this.settings.token).subscribe(
        data => this.uploadSettings(data)
      )
    })

    
    
  }

  async findCompanies(){
    this.clients = await this.dialerService.getClients(this.settings.token, this.defaultClientName)
    if (!this.clients)
      this.clientSearchError = "Too many clients found. Please type a few more characters"
    else {
      this.defaultClientNameValid = this.clients.find(i => i.name === this.defaultClientName)?true:false
    //console.log(this.clients)
    
    if (this.clients.length === 0)
      this.clientSearchError = "No clients found."
    else
      this.clientSearchError = ""
    }
    

  }

  sendSettings(){
    this.users.forEach(user => {
      if (user.shortnum && (typeof user.upsalesUserId !== undefined))
        this.settings.users[user.shortnum] = this.upsalesUsers[user.upsalesUserId]
    })
    if (typeof this.defaultUserId !== undefined)
      this.settings.defaultUser = this.upsalesUsers[this.defaultUserId]
    
    const defaultClient = this.clients.find(i=> i.name === this.defaultClientName)
    if (defaultClient)
      this.settings.defaultClient = defaultClient.id
    
    this.dialerService.sendSettings(this.settings).subscribe(
      data => {

        this.settingsSaved = true
        this.uploadSettings(data)
      }
    )
  }

  async uploadSettings(data: settings){
    this.settings = {...this.settings, ...data}
    if (data.users){
      this.settings.users = {}
      this.users = new Array()
      Object.keys(data.users).forEach(user =>{
        this.users.push({
          shortnum: user, 
          upsalesUserId: this.upsalesUsers.findIndex(i => i.id === data.users[user].id)
            
        })
      })
    }
    this.defaultUserId = this.upsalesUsers.findIndex(i => i.id === data.defaultUser.id) 
    this.defaultClient = await this.dialerService.getClinet(this.settings.token, data.defaultClient)
    this.defaultClientName = this.defaultClient.name
    this.findCompanies()   
  }

  test(){
    console.log("test")
    console.log(this.users)
    console.log(this.defaultUserId)
    console.log(this.upsalesUsers)
  
  }

  addUserTableRow(){
    this.users.push({shortnum: "", upsalesUserId: undefined})
  }

  deleteUserTableRow(index) {
    this.users.splice(index, 1);
  }

}
