import { Component } from '@angular/core';
import { ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'upsales-setup';

  isLeadsChoosen: boolean;
  isDialerChoosen: boolean;
  choose: boolean = false;

  constructor(private route: ActivatedRoute){
    this.route.queryParams.subscribe(params => {
      if (params["mode"] === "dialer")
        this.setupDialer()
      if (params["mode"] === "leads")
        this.setupLeads()

  });
  }

  setupDialer(){
    this.isLeadsChoosen = false
    this.isDialerChoosen = true

    this.choose = true
  }

  setupLeads(){
    this.isLeadsChoosen = true
    this.isDialerChoosen = false

    this.choose = true
  }
}
