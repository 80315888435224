import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { UpsalesToken, UpsalesActivityTypeDto, UpsalesSelfDto, SearchResponce, UpsalesClientDto, UpsalesUserDto, settings, IpmaxiUserId } from './types';

@Injectable({
    providedIn: 'root'
  })

  

  export class upsalesDialerService {
    constructor(private http: HttpClient,) { }

  sendSettingsUrl = 'https://upsales.leadcm.com/api/v1/setup/settings'
  
  async getActivityTypes(token: UpsalesToken): Promise<UpsalesActivityTypeDto[]> {
    const url = `https://integration.upsales.com/api/v2/activitytypes/activity/?token=${ token }`
    const data = await this.http.get<SearchResponce<UpsalesActivityTypeDto>>(url).toPromise()
    return data.data
  }

  getUserSelf(token: UpsalesToken): Observable<UpsalesSelfDto>{
    const url = `https://integration.upsales.com/api/v2/self?token=${ token }`
    return this.http.get<UpsalesSelfDto>(url)
  }

  async getClients(token: UpsalesToken, companyName: string): Promise <UpsalesClientDto[] | undefined>{
    const url = `https://integration.upsales.com/api/v2/accounts/`
    const data =  await this.http.get<SearchResponce<UpsalesClientDto>>(url,
      {
        params: {
          token,
          name: `wc:*${ companyName }*`
        }
      }).toPromise()
    
    return data.data.length < 100?data.data:undefined
  }

  async getClinet(token: UpsalesToken, companyId: string|number): Promise<UpsalesClientDto>{
    const url = `https://integration.upsales.com/api/v2/accounts/${companyId}?token=${token}`
    const data = await this.http.get(url).toPromise()
    return data["data"]
  }

  async getUsers(token: UpsalesToken): Promise <UpsalesUserDto[]>{
    const url = `https://integration.upsales.com/api/v2/users?token=${ token }`
    const data = await this.http.get<SearchResponce<UpsalesUserDto>>(url).toPromise()
    return data.data
  }



  sendSettings(settings: settings): Observable <settings>{
    const url = this.sendSettingsUrl
    return this.http.post<settings>(url, settings)
  }

  getSettings(userId: IpmaxiUserId, token: UpsalesToken): Observable <settings>{
    const url = this.sendSettingsUrl
    return this.http.get<settings>(url, {params: {
      userId: userId.toString(),
      token
    }})
  }

  
 
  }